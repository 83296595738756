<template>
  <div class="full-area">
    <transition-group
      name="slide" tag="div" class="full-area row-area"
    >
      <LikertBubbles
        :data="question"
        :key="Math.random().toString + rgqid.toString() + question.sys.id"
        :id="question.sys.id"
        v-if="isReady && question && question.sys && question.sys.contentType.sys.id === 'likertBubbles'"
        v-on:next="next($event)"
        v-on:previous="previous($event)"
      ></LikertBubbles>
      <MultiTextSelection
        :data="question"
        :key="Math.random().toString + rgqid.toString() + question.sys.id"
        :id="question.sys.id"
        v-if="isReady && question && question.sys && question.sys.contentType.sys.id === 'multiTextSelection'"
        v-on:previous="previous($event)"
        v-on:next="next($event)"
      ></MultiTextSelection>
      <EmojiBubbles
        :data="question"
        :key="Math.random().toString + rgqid.toString() + question.sys.id"
        :id="question.sys.id"
        v-if="isReady && question && question.sys && question.sys.contentType.sys.id === 'emojiBubbles'"
        v-on:next="next($event)"
        v-on:previous="previous($event)"
      ></EmojiBubbles>
      <Dilemma
        :data="question"
        :key="Math.random().toString + rgqid.toString() + question.sys.id"
        :id="question.sys.id"
        v-if="isReady && question && question.sys && question.sys.contentType.sys.id === 'dilemma'"
        v-on:next="next($event)"
        v-on:previous="previous($event)"
      ></Dilemma>
      <Thumbs
        :data="question"
        :key="Math.random().toString + rgqid.toString() + question.sys.id"
        :id="question.sys.id"
        v-if="isReady && question && question.sys && question.sys.contentType.sys.id === 'thumbs'"
        v-on:next="next($event)"
        v-on:previous="previous($event)"
      ></Thumbs>
      <Bilderset
        :data="question"
        :key="Math.random().toString + rgqid.toString() + question.sys.id"
        :id="question.sys.id"
        v-if="isReady && question && question.sys && question.sys.contentType.sys.id === 'bilderset'"
        v-on:next="next($event)"
        v-on:previous="previous($event)"
      ></Bilderset>
      <Screen
        :key="'screen' + question.sys.id"
        :data="question"
        v-if="isReady && question && question.sys && question.sys.contentType.sys.id === 'screen'"
        v-on:fullscreen:off="fullscreenOff()"
        v-on:next="next($event)"
        v-on:previous="previous($event)"
      ></Screen>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'RandomGroup',

  props: [ 'data' ],

  components: {
    LikertBubbles: () => import('Components/questions/LikertBubbles'),
    MultiTextSelection: () => import('Components/questions/MultiTextSelection'),
    Dilemma: () => import('Components/questions/Dilemma'),
    Thumbs: () => import('Components/questions/Thumbs'),
    EmojiBubbles: () => import('Components/questions/EmojiBubbles'),
    Bilderset: () => import('Components/questions/Bilderset'),
    Screen: () => import('Components/questions/Screen'),
  },

  data () {
    return {
      isReady: false,
      question: {},
      randomGroupOrder: 0,
      form: {
        type: `randomGroup`,
        children: [],
        identifier: this.data.fields.identifier,
        question: null,
        questionLength: null,
        numberOfOptions: null,
        id: this.data.sys.id,
        selected: [],
        labelSelection: [],
        splitSelected: {},
        timeToCompletion: null,
        timestamps: {
          start: new Date(),
          finish: null,
          valid: null
        }
      }
    }
  },

  computed: {
    sid () {
      return this.$route.params.sid
    },
    qid () {
      return parseInt(this.$route.params.qid, 10)
    },
    rgqid () {
      return parseInt(this.$route.params.rgqid, 10)
    },
    children () {
      return this.$store.state.randomGroups[this.qid]
    }
  },

  methods: {
    next (data) {
      this.$emit('fullscreen')
      let response = {
        questionData: this.question,
        sensataId: `${this.$store.state.fingerprint}_${this.$store.state.survey.browserReport.ip}`,
        type: 'questionEvent',
        selected: data.selected,
        question: data.question,
        identifier: data.identifier,
        surveyId: this.sid,
        timestamp: new Date().getTime(),
        //qid: parseInt(this.$router.currentRoute.params.qid, 10) + this.randomGroupOrder / 10,
        qid: `${this.$route.params.qid}.${this.$route.params.rgqid}`,
        randomGroupOrder: this.randomGroupOrder,
        // todo: add a way to prevent automated submissions
      }
      data.randomGroupOrder = this.randomGroupOrder
      this.$store.commit('STORE_RANDOM_GROUP_NAVIGATOR', { qid: this.qid, rgqid: this.rgqid})
      this.randomGroupOrder = this.$store.state.randomGroupsNavigator[this.qid]

      // send to parent iFrame
      window.parent.postMessage(JSON.stringify(response), '*')

      var skip = 0
      this.$store.dispatch('SUBMIT_TO_ALT_TRACKING_DB', { response: response })
      this.$store.commit('STORE_RESPONSE_NEW', { response: data, qid: parseInt(this.$router.currentRoute.params.qid, 10) + this.randomGroupOrder / 10 })

      if (this.rgqid < this.children.length) {
        const params = {
          sid: this.$route.params.sid,
          qid: this.$route.params.qid,
          rgqid: this.rgqid + 1
        }
        this.$emit('nextrg', params)
      } else {
        this.$emit('next', this.form)
      }
    },
    initialize () {
      // create maps if there aren't any
      if (!(this.children && this.children.length)) {
        let children = this.data.fields.doNotRandomize ? this.data.fields.children : shuffleArray(this.data.fields.children)
        this.$store.commit('STORE_RANDOM_GROUP_QID', { qid: this.qid, children })
      }
      if (typeof this.rgqid === 'undefined' || isNaN(this.rgqid) || !this.question.fields) {
        if (typeof this.children === 'undefined') {
          // it means we don't have children loaded, go back to the parent question
          const params = {
            sid: this.sid,
            qid: this.qid
          }
          this.$emit('nextrg', params)
          return
        }
        if (!this.question.fields) {
          this.question = this.$store.state.randomGroups[this.qid][this.rgqid - 1]
          this.$emit('updateChild', this.question)
          this.isReady = true
        }

        if (typeof this.$store.state.randomGroupsNavigator[this.qid] === 'number' && this.$store.state.randomGroupsNavigator[this.qid] > 1) {
          this.$store.commit('STORE_RANDOM_GROUP_NAVIGATOR', { qid: this.qid, rgqid: this.rgqid - 1 >= 1 ? this.rgqid : null})
          this.$router.go(-1)
        } else {
          const params = {
            sid: this.sid,
            qid: this.qid,
            rgqid: 1
          }
          this.$emit('nextrg', params)
        }
      }
    }
  },

  mounted () {
    this.initialize()
  },

  watch: {
    'qid': function (newVal) {
    },
    'rgqid': function (newVal) {
      this.question = this.children[this.rgqid - 1]
      this.$emit('updateChild', this.question)
      this.isReady = true
      this.$store.commit('STORE_RANDOM_GROUP_NAVIGATOR', { qid: this.qid, rgqid: this.rgqid || 1})
      if (isNaN(newVal)) {
        const params = {
          sid: this.sid,
          qid: this.qid,
          rgqid: 1
        }
        this.$emit('nextrg', params)
      }
    }

  }
}
function shuffleArray(array) {
  const shuffledArray = [...array]
  for (let i = shuffledArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]
  }
  return shuffledArray;
}
</script>

<style scoped lang="scss">
</style>
